<template>
  <a-modal
    :get-container="modalRef"
    v-model:visible="visible"
    :mask-closable="false"
    title="修改月租套餐"
    :after-close="afterClose"
  >
    <template #footer>
      <a-button :disabled="disabled" @click="visible = false">取消</a-button>
      <a-button :disabled="disabled" type="primary" @click="doSubmit"
      >确定</a-button
      >
    </template>
    <a-form :label-col="{ span: 4 }">
      <a-form-item label="套餐名称" v-bind="validateInfos.packageName">
        <a-input
          v-model:value="monthlyRentalPackageVo.packageName"
          :maxlength="16"
          placeholder="请输入套餐名称"
          allow-clear
        ></a-input>
      </a-form-item>
      <a-form-item label="选择车场" v-bind="validateInfos.parkingLotId" >
        <a-select
          v-model:value="monthlyRentalPackageVo.parkingLotId"
          mode="multiple"
          notFoundContent="暂无数据"
          placeholder="请选择"
          allow-clear
          disabled="true"
        >
          <a-select-option
            v-for="item in parkingLotList"
            :key="item.id"
            :value="item.id"
          >{{ item.name }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item label="套餐类型" v-bind="validateInfos.packageType" >
        <a-select
          disabled="true"
          :maxlength="30"
          v-model:value="monthlyRentalPackageVo.packageType"
          placeholder="请选择"
          allow-clear
          :options="packageTypes"
        >
        </a-select>
      </a-form-item>
      <a-form-item label="套餐金额" v-bind="validateInfos.packagePayAmount">
        <a-input
          v-model:value="monthlyRentalPackageVo.packagePayAmount"
          :maxlength="30"
          placeholder="请输入套餐金额"
          allow-clear
        >
          <template #addonAfter>元</template>
        </a-input>
      </a-form-item>

    </a-form>
  </a-modal>
</template>

<script>
import { ref, onMounted, toRefs } from "vue";
import useValidateRules from "@/hooks/useValidateRules";
import useMessage from "@/hooks/useMessage";
import { Form } from "ant-design-vue";
import { edit,detail } from "@/api/parking/monthlyRentalPackage";
import useMonthlyRentalPackage from "../../../../hooks/parking/useMonthlyRentalPackage";
import axios from "axios";

const useForm = Form.useForm;
export default {
  name: "Edit",
  props: ["modalRef", "id"],
  emits: ["close"],
  setup(props, { emit }) {
    const disabled = ref(false);
    const { getParkingLotList, parkingLotList, monthlyRentalPackageVo, packageTypes,moneyZ,money } = useMonthlyRentalPackage();
    const { monthlyRentalPackageRules } = useValidateRules();
    const { showErr, showConfirm, showLoading, closeLoading, showTipMessage } =
      useMessage();
    const { validateInfos, validate } = useForm(
      monthlyRentalPackageVo,
      monthlyRentalPackageRules,
    );
    const visible = ref(true);
    let refresh = false;
    const afterClose = () => {
      emit("close", refresh);
    };
    const doSubmit = () => {
      validate().then(async () => {
        let action = await showConfirm("修改月租套餐信息?");
        if (action) {
          disabled.value = true;
          await showLoading();
          try {
            let obj = {
              packageName: monthlyRentalPackageVo.packageName,
              packagePayAmount: moneyZ(monthlyRentalPackageVo.packagePayAmount),
              packageType: monthlyRentalPackageVo.packageType,
              parkingLotId: (monthlyRentalPackageVo.parkingLotId).join(','),
            };
            await edit(props.id,obj);
            await closeLoading();
            await showTipMessage("添加成功");
            refresh = true;
            visible.value = false;
          } catch (e) {
            await closeLoading();
            await showErr(e);
            disabled.value = false;
          }
        }
      });
    };
    onMounted(async () => {
      try {
        // eslint-disable-next-line no-unused-vars
        let [r1, r2] = await axios.all([getParkingLotList(), detail(props.id)]);
        monthlyRentalPackageVo.packageName = r2.data.packageName;
        monthlyRentalPackageVo.packageType = r2.data.packageType;
        monthlyRentalPackageVo.packagePayAmount =money(r2.data.packagePayAmount);
        monthlyRentalPackageVo.parkingLotId = (r2.data.parkingLotId).split(',');
        monthlyRentalPackageVo.parkingLotName = r2.data.parkingLotName;

      } catch (e) {
        await showErr(e);
      }
    });
    return {
      ...toRefs(props),
      disabled,
      visible,
      afterClose,
      getParkingLotList,
      parkingLotList,
      packageTypes,
      validateInfos,
      monthlyRentalPackageVo,
      doSubmit,
      moneyZ,
      money,
    };
  },
};
</script>

<style scoped></style>
